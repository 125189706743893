import * as Yup from "yup"

const YEAR_NOW = new Date(Date.now()).getFullYear()
const REQUIRED_MESSAGE = "This field is required"
const INVALID_YEAR = "Please input a valid year"

export const formInitialValues = (module, userData, address = "") => {
  switch (module) {
    case "update-password":
      return { oldPassword: "", newPassword: "", confirmPassword: "" }

    case "edit":
      return {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        mobileNumber: userData?.mobileNumber,
        birthday: {
          month: userData?.birthday.month,
          date: userData?.birthday.date,
          year: userData?.birthday.year,
        },
        emergencyContactName: userData?.emergencyContact?.name,
        emergencyContactNumber: userData?.emergencyContact?.mobileNumber,
        emergencyContactEmail: userData?.emergencyContact?.email,
        emergencyContactRelationship: userData?.emergencyContact?.relationship,
        gender: userData?.gender,
        site: userData?.site,
        department: userData?.department,
        employeeNumber: userData?.employeeNumber,
        personalEmail: userData?.personalEmail,
        userData: userData,
      }

    case "edit-address":
    case "add-address":
      return {
        index: address?.index,
        address: {
          addressType:
            address?.type?.replace(/^\w/, (c) => c.toUpperCase()) || "",
          streetAddress: address?.streetAddress || "",
          primary: address?.primary || false,
          province: address?.province
            ? {
                value: address?.province || "",
                label: address?.province || "",
              }
            : null,
          city: address?.city
            ? {
                value: address?.city || "",
                label: address?.city || "",
              }
            : null,
          barangay: address?.barangay
            ? {
                value: address?.barangay || "",
                label: address?.barangay || "",
              }
            : null,
        },
      }
  }
}

export const profileFormSchema = (module) => {
  switch (module) {
    case "update-password":
      return updatePasswordSchema

    case "edit":
      return editProfileSchema

    case "add-address":
    case "edit-address":
      return addressValidationSchema
  }
}

const updatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(REQUIRED_MESSAGE),
  newPassword: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(7, "Your password must be at least seven characters long.")
    .max(80, "Your password must not exceed 80 characters in length.")
    .matches(
      /(?=.*[a-z])/,
      "Your password must contain at least one lowercase character."
    )
    .matches(
      /(?=.*[A-Z])/,
      "Your password must contain at least one uppercase character."
    )
    .matches(/(?=.*[0-9])/, "Your password must contain at least one number.")
    .matches(
      /(?=.*[!|@|#|$|%|&|_|+|=|.|-])/,
      "Your password must contain at least one of these special characters: !@#$%&_-+=."
    )
    .matches(
      /^[a-zA-Z0-9!@#$%&_+=.-]{7,}$/,
      "Your password contains an invalid special character. Please use any from the following only: !@#$%&_-+=."
    ),
  confirmPassword: Yup.string()
    .required(REQUIRED_MESSAGE)
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
})

const editProfileSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, REQUIRED_MESSAGE)
    .max(11, REQUIRED_MESSAGE)
    .matches(/^09[0-9]{9}$/, REQUIRED_MESSAGE)
    .required(REQUIRED_MESSAGE),
  //Please input a valid mobile number in this format: 09991234567
  // birthday: Yup.object({
  //   month: Yup.object().shape({
  //     value: Yup.string().required(REQUIRED_MESSAGE).nullable(),
  //   }),
  //   date: Yup.object().shape({
  //     value: Yup.number().required(REQUIRED_MESSAGE).nullable(),
  //   }),
  //   year: Yup.number()
  //     .min(1900, INVALID_YEAR)
  //     .max(YEAR_NOW, INVALID_YEAR)
  //     .required(REQUIRED_MESSAGE),
  // }),
  emergencyContact: Yup.object().shape({
    name: Yup.string().required(REQUIRED_MESSAGE),
    mobileNumber: Yup.string().required(REQUIRED_MESSAGE),
    email: Yup.string().required(REQUIRED_MESSAGE),
    relationship: Yup.string().required(REQUIRED_MESSAGE),
  }),
})

const addressValidationSchema = Yup.object().shape({
  address: Yup.object().shape({
    addressType: Yup.string().required(REQUIRED_MESSAGE),
    streetAddress: Yup.string().required(REQUIRED_MESSAGE),
    barangay: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    city: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    province: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
})
